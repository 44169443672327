<template>
    <div class="connect">
        <div class="p1">立即申请代理商</div>
        <p class="p2">请填写真实信息，我们将有工作人员在1-2个工作日内联系您</p>
        <input type="text" name="phone" v-model="form.phone" placeholder="请输入您的手机号（必填）">
        <input type="text" name="name" v-model="form.name" placeholder="请输入您的姓名">
        <input type="text" name="company" v-model="form.company" placeholder="请输入您的公司名称">
        <button @click="channel">提交申请</button>
        <div class="cooperFooter" >投资有风险,选择需谨慎</div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    name: '',
                    phone: '',
                    company: ''
                },
            }
        },
        methods: {
            channel() {
                let that = this;
                if (!this.checkPhone(this.form.phone)) {
                    return;
                }

                axios({
                    method: 'post',
                    url: '/channel',
                    data: this.form
                })
                    .then(function (response) {
                        console.log(response.data.message);
                        if (response.status == 201) {
                            swal({
                                title: response.data.message,
                                text: "",
                                icon: "success"
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log(error.response);
                        if (error.response.status == 400 || error.response.status == 500) {
                            swal({
                                title: error.response.data.message,
                                text: "",
                                icon: "error"
                            });
                        }
                    });
            },
            checkPhone(phone) {
                if (!(/^1[34578]\d{9}$/.test(phone))) {
                    swal({
                        title: "手机号码有误，请重填",
                        text: "",
                        icon: "error"
                    });
                    return false;
                }
                return true;
            }
        },
    }
</script>

<style scoped lang="scss">
@function px( $px ){
    @return $px/$designWidth*10 + rem;
  }

  $designWidth : 750;
    .connect {
        width: 100%;
        background-color: #f9f9f9;
        padding: px(50) 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .p1 {
            width: 100%;
            text-align: center;
            font-size: px(32);
            color: #333;
            font-size:px(32);
            font-family:PingFang SC;
            font-weight:bold;
            color:rgba(51,51,51,1);
            display: block;
            text-align: center;
        }

        .p2 {
            width: 100%;
            text-align: center;
            font-size: px(20);
            color: #999;
            margin-top: px(10);
            margin-bottom: px(50);
        }

        input {
            width: px(690);
            height: px(86);
            box-sizing: border-box;
            display: block;
            margin: 0 auto;
            margin-bottom: px(30);
            border-radius: 3px;
            font-size: 16px;
            border: solid 1px #ededed;
            padding:0 px(29);
        }

        button {
            width: px(690);
            height: px(86);
            background-color: #f03b44;
            border-radius: 3px;
            border: solid 1px #f03b44;
            font-size: 16px;
            color: #fff;
        }
        input::-webkit-input-placeholder {
            color:rgba(153,153,153,1);
            font-size: px(24);
        }
        .cooperFooter{
            width: px(750);
            height: px(46);
            font-size:px(26);
            font-family:Adobe Heiti Std;
            font-weight:normal;
            text-align: center;
            line-height: px(46);
            color: #999;
            margin-top: px(10)
        }
    }

</style>

