
<script>
export default {
    props: ['articles', 'request'],
    data() {
        return {
            url: this.articles.next_page_url,
            query: this.request,
            data: this.articles.data,
            init: true,
            category_id: 0,
        }
    },
    mounted() {
        if (typeof(this.query.category_id) != 'undefined') {
            this.category_id = this.query.category_id
        }
    },
    methods: {
        category(category_id) {
            let that = this;
            this.category_id = category_id
            this.query = {
                ...this.query,
                category_id: category_id
            }
            axios({
                method: 'get',
                url: '/',
                params: {
                    category_id: category_id
                },
            })
            .then(function (response) {
                that.init = false
                that.url = response.data.next_page_url
                that.data = response.data.data
            })
            .catch(function (error) {
                console.log(error.response);

                if (error.response.status == 400) {
                    that.message = error.response.data.message
                }
            });
        },
        more() {
            let that = this;
            if (this.url == null) {
                return;
            }
            axios({
                method: 'get',
                url: this.url,
                params: this.query,
            })
            .then(function (response) {
                if (response.status == 200) {
                    that.init = false
                    that.url = response.data.next_page_url
                    response.data.data.forEach(element => {
                        that.data.push(element)
                    });
                }
            })
            .catch(function (error) {
                console.log(error.response);

                if (error.response.status == 400) {
                    that.message = error.response.data.message
                }
            });
        },
    },
}
</script>

