/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('qht-experience2', require('./components/Experience2.vue').default);
Vue.component('qht-contact', require('./components/Contact.vue').default);
Vue.component('qht-contact-m', require('./components/Contact_m.vue').default);
Vue.component('qht-news', require('./components/News.vue').default);
Vue.component('qht-service', require('./components/Service.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import Swiper from 'swiper'
// import 'amfe-flexible'
const app = new Vue({
    el: '#app',
    data:()=>({
        isShow: true,
        choose: 1,
        step: 0,
        priceFrom: {
            name: '',
            phone: ''
        },
        registerError: false,
        isSuccess: false,
        errorData: {}
    }),
    mounted() {
        var swiper = new Swiper('.swiper-container', {
            spaceBetween: 30,
            centeredSlides: true,
            // 自动轮播
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            loop: true
        });
        $('.swiper-container>.swiper-wrapper> .swiper-slide').mouseenter(function () {
            swiper.autoplay.stop();
        })
        $('.swiper-container>.swiper-wrapper> .swiper-slide').mouseleave(function () {
            swiper.autoplay.start();
        })

        $('.more_all').click(function(){
            if($("#header").hasClass("all")){
                $("#header").removeClass("all");
                $("#header").removeClass("bgred");
            }else{
                $("#header").addClass('all');
                $("#header").addClass('bgred');
            }
        });
        $('#app').click(function() {
            if($("#header").hasClass("all")){
                $("#header").removeClass("all");
            }
        })
        $('#openZoosUrl').click(function(){
            window.openZoosUrl('bchatwin');
          });
        $("#case").on("click","li",function(event){
            if($(event.target)[0].nodeName == "IMG"){
            $(event.target).parent().siblings().each(e => {
                var siblings = $(event.target).parent().siblings()[e]
                $(siblings).removeClass('active')
            });
            $(event.target).parent().addClass('active')
            setTimeout(() => {
                $(event.target).parent().removeClass('active')
            }, 3000);
            return false
            }
            $(event.target).addClass('active')
        })
        $("#content").on("click","li",function(event){
            if($(event.target)[0].nodeName == "IMG"){
                $(event.target).parent().parent().siblings().each(e => {
                    var siblings = $(event.target).parent().parent().siblings()[e]
                    $(siblings).removeClass('active')
                });
                $(event.target).parent().parent().addClass('active')
                setTimeout(() => {
                    $(event.target).parent().parent().removeClass('active')
                }, 3000);
                return false
            }
            $(event.target).addClass('active')
        })
        new Swiper('.pattern_mobile_swiper', {
            pagination: {
                el: '.pattern-swiper-pagination',
                clickable: true,
                type: 'custom',
                renderCustom: function (swiper, current, total) {
                    var customPaginationHtml = "";
                    for(var i = 0; i < total; i++) {
                        if(i == (current - 1)) {
                            customPaginationHtml += "<span class='swiper-pagination-customs swiper-pagination-customs-active'></span>";
                        } else {
                            customPaginationHtml += "<span class='swiper-pagination-customs'></span>";
                        }
                    }
                    return customPaginationHtml;
                }
            },
        });
        new Swiper('.program_swiper', {
            autoplay: {
                delay: 10000
            },
            loop: true,
            pagination: {
                el: '.program-swiper-pagination',
                clickable: true,
                type: 'custom',
                renderCustom: function (swiper, current, total) {
                    var customPaginationHtml = "";
                    for(var i = 0; i < total; i++) {
                        if(i == (current - 1)) {
                            customPaginationHtml += "<span class='swiper-pagination-customs swiper-pagination-customs-active'></span>";
                        } else {
                            customPaginationHtml += "<span class='swiper-pagination-customs'></span>";
                        }
                    }
                    return customPaginationHtml;
                }
            },
        });
        new Swiper('.swiper-ditch', {
            slidesPerView: 3,
            spaceBetween: 30,
            centeredSlides: true,
            loop: true,
            slidesPerView: 1.93,
            pagination: {
                el: '.swiper-pagination1',
                clickable: true,
            },
        });
        new Swiper('.art_swiper', {
            slidesPerView: 3,
            spaceBetween: 15,
            centeredSlides: true,
            loop: true,
            slidesPerView: 1.2,
            autoplay: {
                delay: 10000
            },
            pagination: {
                el: '.art-swiper-pagination',
                type: 'custom',
                renderCustom: function (swiper, current, total) {
                    var customPaginationHtml = "";
                    for(var i = 0; i < total; i++) {
                        if(i == (current - 1)) {
                            customPaginationHtml += "<span class='swiper-pagination-customs swiper-pagination-customs-active'></span>";
                        } else {
                            customPaginationHtml += "<span class='swiper-pagination-customs'></span>";
                        }
                    }
                    return customPaginationHtml;
                }
            }
        });
        var programSwiper= new Swiper('.swiper-program-content', {
            loop: true,
            autoplay: {
                delay: 10000
            },
            on:{
                slideChangeTransitionEnd: function(){
                    var index = this.activeIndex - 1
                    if (index == 4) {
                        index = 0
                    }
                    $('.nav_item').eq(index).addClass('active').children('.nav_line').css('display','block')
                    $('.nav_item').eq(index).siblings().removeClass('active').children('.nav_line').css('display','none')
                }
            },
            pagination: {
                el: '.program_pagination',
                type: 'custom',
                renderCustom: function (swiper, current, total) {
                    var customPaginationHtml = "";
                    for(var i = 0; i < total; i++) {
                        if(i == (current - 1)) {
                            customPaginationHtml += "<span class='swiper-pagination-customs swiper-pagination-customs-active'></span>";
                        } else {
                            customPaginationHtml += "<span class='swiper-pagination-customs'></span>";
                        }
                    }
                    return customPaginationHtml;
                }
            }
        });
        $('.nav_item').on('click',function(){
            var index = $(this).index() + 1;
            programSwiper.slideTo(index, 1000, false)
            $(this).addClass('active').children('.nav_line').css('display','block')
            $(this).siblings().removeClass('active').children('.nav_line').css('display', 'none')
        });
        new Swiper('.mobile_swiper', {
            loop: true,
            autoplay: {
                delay: 10000
            },
            pagination: {
                el: '.mobile-swiper-pagination',
                type: 'custom',
                renderCustom: function (swiper, current, total) {
                    var customPaginationHtml = "";
                    for(var i = 0; i < total; i++) {
                        if(i == (current - 1)) {
                            customPaginationHtml += "<span class='swiper-pagination-customs swiper-pagination-customs-active'></span>";
                        } else {
                            customPaginationHtml += "<span class='swiper-pagination-customs'></span>";
                        }
                    }
                    return customPaginationHtml;
                }
            }
        });
        var modeSwiper= new Swiper('.mode_swiper', {
            loop: true,
            autoplay: {
                delay: 8888
            },
            on:{
                slideChangeTransitionEnd: function(){
                    var index = this.activeIndex - 1
                    if (index == 5) {
                        index = 0
                    }
                    $('.mode-item').eq(index).addClass('active').siblings().removeClass('active')
                    $('.mode-pagination-customs').eq(index).addClass('active').siblings().removeClass('active')
                }
            }
        });
        $('.mode-item').on('click',function(){
            var index = $(this).index() + 1;
            modeSwiper.slideTo(index, 1000, false)
            $(this).addClass('active').siblings().removeClass('active')
        });
        var solveSwiper= new Swiper('.swiper-slove', {
            loop: true,
            autoplay: {
                delay: 20000
            },
            on:{
                slideChangeTransitionEnd: function(){
                    var index = this.activeIndex - 1
                    if (index == 4) {
                        index = 0
                    }
                    $('.tabbar_item').eq(index).addClass('active').siblings().removeClass('active')
                }
            }
        });
        $('.tabbar_item').on('click',function(){
            var index = $(this).data('index') + 1;
            solveSwiper.slideTo(index, 1000, false);
            $(this).addClass('active').siblings().removeClass('active')
        });
        var solveListSwiper= new Swiper('.swiper-slove_list', {
            loop: true,
            autoplay: {
                delay: 20000
            },
            on:{
                slideChangeTransitionEnd: function(){
                    var index = this.activeIndex - 1
                    if (index == 4) {
                        index = 0
                    }
                    $('.solve_tag').eq(index).addClass('active').siblings().removeClass('active')
                }
            }
        });
        $('.solve_tag').on('click',function(){
            var index = $(this).data('index') + 1;
            solveListSwiper.slideTo(index, 1000, false);
            $(this).addClass('active').siblings().removeClass('active')
        });
        new Swiper('.swiper-register-container', {
            effect: 'coverflow',
            loop: true,
            autoplay: {
                delay: 3000
            },
            slidesPerView: 3,
            direction: 'vertical',
            coverflow: {
        		stretch: 10,
                depth: 0,
                modifier: 2,
        		slideShadows : false
        	}
        });

        $('.success_dialog_btn').on('click', function() {
            $('.success_dialog_box').css({
                'display': 'none'
            })
        })
    },
    methods: {
        goTop() {
            let distance = document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
            let step = distance / 50; //每步的距离
            (function jump() {
                if (distance > 0) {
                    distance -= step;
                    window.scrollTo(0, distance);
                    setTimeout(jump, 10)
                }
            })()
        },
        close() {
            this.isShow = !this.isShow
        },
        change(val) {
            this.choose = val
            let line = document.getElementsByClassName("line")[0]
            this.step = line.offsetWidth * (val*1-1) +'px'
        },
        bindSubmit() {
            let href = window.location.search
            href = href.substring(1, href.length);
            const arr = href.split("&");
            const obj = new Object();
            let from_url = window.location.origin
            if (href) {
                for(let i = 0; i < arr.length; i++) {
                    const tmp_arr = arr[i].split("=");
                    obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
                }
                for (const key in obj) {
                    if (obj.hasOwnProperty.call(obj, key)) {
                        if (key.indexOf(";") > -1) {
                            obj[key.substring(key.indexOf(";") + 1, key.length)] = obj[key]
                        }
                    }
                }
            }
            const params = { ...this.priceFrom , ...obj }
            let that = this;
            if (!this.checkPhone(that.priceFrom.phone)) {
                return;
            }
            if (JSON.stringify(obj) != '{}') {
                from_url = from_url + '/src_id=' + obj.src_id + '&myq=' + obj.myq + '&utm_source=' + obj.utm_source + '&utm_medium=' + obj.utm_medium + '&utm_campaign=' + obj.utm_campaign + '&utm_term=' + obj.utm_term + '&utm_content=' + obj.utm_content
            }
            params.from_url = from_url
            axios({
                method: 'post',
                url: '/experience',
                data: params
            }).then(function (response) {
                if (response.status == 201) {
                    $('.success_dialog_box').css({
                        'display': 'block'
                    })
                    that.isSuccess = true
                    that.priceFrom = {
                        name: '',
                        phone: ''
                    }
                }
            }).catch(function (error) {
                $('.success_dialog_box').css({
                    'display': 'block'
                })
                that.isSuccess = false
                that.errorData = error.response.data
            });
        },
        checkPhone(phone) {
            if (!(/^1[3-9]\d{9}$/.test(phone))) {
                this.registerError = true
                return false;
            }
            return true;
        }
    }
})
