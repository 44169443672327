<template>
<div class="footers">
    <div class="search" v-if="show">
        <div class="h3" style="width:100%;font-size:px(16)">现在选择千汇团，让你的每一位客户更加满意</div>
        <div style="display:flex;">
            <input name="tel" type="tel" v-model="phone" placeholder="输入手机号稍后我们会与您联系" required>
            <button @click="experience" class="freeExp"><p style="-webkit-transform:scale(0.8);">免费体验</p></button>
        </div>
    </div>
    <div class="consult">
        <div class="consult_list">
            <div class="msg" id="openZoosUrl">
                <a href="http://www.qianhuituan.cn/">
                    <div class="index_pic"></div>
                    <p>首页</p>
                </a>
            </div>
            <div class="msg" id="openZoosUrl">
                <a href="https://pbt.zoosnet.net/LR/Chatpre.aspx?id=PBT22890741&cid=72c80bd967744a8d969d17cbc99f3a26&lng=cn&sid=ea22cc6832fb4da881d80bbf4656e5b6&p=http%3A//m.qianhuituan.cn/introduce&rf1=http%3A//m.qianhuituan&rf2=.cn/article%3Fpage%3D5&msg=&d=1578578878308">
                    <div class="msg_img"></div>
                    <p>在线咨询</p>
                </a>
            </div>
            <div class="msg" id="openZoosUrl">
                <a :href="'tel: ' + tel_pic">
                    <div class="tel_pic"></div>
                    <p class="telMsg">电话咨询</p>
                </a>
            </div>
        </div>
        <div class="freeBg">
            <a :href="'http://m.qianhuituan.cn/register?src_id=' + src_id + '&myq=' + myq">
                <p class="freeToUse">免费试用</p>
            </a>
        </div>
    </div>
    <div class="someMsg">
        <div class="footerMsg">
            <img src="https://img.qianhuituan.cn/web/images/mobile_foot_logo.gif" alt="footerMsg">
        </div>
        <div class="us">联系我们</div>
        <div class="usMsg">联系电话: 400-089-9796</div>
        <div class="usMsg">合伙服务: ltr@qbyun.com</div>
        <div class="usMsg usMsgLast">办公时间:周一至周六9:00-18:00</div>
        <div class="us">关于我们</div>
        <div class="flexMsg">
            <div class="usMsg" @click="tocompany">公司介绍</div>
            <div class="usMsg aboutMsg" @click="tocollege">新动态</div>
            <div class="usMsg flexMsgLast" @click="tous">联系我们</div>
        </div>
        <div v-if="information">
            <div class="us">动态资讯</div>
            <div class="usMsg">产品经销商结合电商系统能够做到什么样的优势营销?</div>
            <div class="usMsg">社区团购线下发展会员的基本途径</div>
            <div class="usMsg flexMsgLast">社群团购营销过程中的社区团购多元化营销角度的探求</div>
        </div>
        <div class="line">
            <div class="footerMsgLast">杭州粉盟科技有限公司</div>
            <div class="footerMsgLast">@2008- 2021 qianhuituan.cn 浙ICP备16009124号-14</div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        props: ['paramers'],
        data() {
            return {
                phone: '',
                src_id: '61',
                myq: '',
                message: '',
                tel_pic: '',
                can: false,
                show: false,
                information: true,
                registerUrl: ''
            }
        },
        created() {
            this.getroute()
        },
        mounted() {
             let data = JSON.parse(this.paramers);

             if (data['src_id']) {
                 this.src_id = data['src_id'];
             }

             if (data['myq']) {
                 this.myq = data['myq'];
             }

             if (data['tel_pic']) {
                 this.tel_pic = data['tel_pic'];
             }
             this.registerUrl = window.location.href
        },
        methods: {
            tocompany(){
                window.location.href="http://www.qianhuituan.cn/contact"
            },
            tocollege(){
                window.location.href="http://www.qianhuituan.cn/article"
            },
            tous(){
                // window.location.href="http://m.qianhuituan.test/contact"
                // window.location.href="tencent://message/?uin=283884942&Site=Sambow&Menu=yes"
                window.location.href="tel: 400-089-9796"
            },
            clickHandle(){
                window.location.href="http://www.beian.miit.gov.cn/state/outPortal/loginPortal.action;jsessionid=UruLLplvwt9NLiX4k0vmCenNEVsbS7bzfNeC-fArmBsUskuysxL7!-1430904473"
            },
            getroute() {
                if(window.location.pathname === '/video') {
                    // this.show = false
                } else {
                    // this.show =true
                }
                if(window.location.href !== 'http://www.qianhuituan.cn/') {
                    this.information = false
                } else {
                    this.information =true
                }
            },
            experience() {
                let that = this;
                if (!this.checkPhone(this.phone)) {
                    return;
                }
                const params = {
                    phone: this.phone,
                    src_id: this.src_id,
                    myq: this.myq
                }
                params.from_url = window.location.origin + from_url + '/src_id=' + this.src_id + '&myq=' + this.myq
                axios({
                    method: 'post',
                    url: '/experience',
                    data: params
                })
                .then(function (response) {
                    alert(response.data.message)
                    if (response.status == 201) {
                        that.message = response.data.message
                    }
                })
                .catch(function (error) {
                    alert(error.response.data.message)
                    if (error.response.status == 400) {
                        that.message = error.response.data.message
                    }
                });
            },
            checkPhone(phone){
                if(!(/^1[34578]\d{9}$/.test(phone))){
                    alert('手机号码有误，请重填');
                    return false;
                }

                return true;
            }
        },
    }
</script>
<style scoped lang='scss'>
@function px( $px ){
    @return $px/$designWidth*10 + rem;
  }

  $designWidth : 375;
    .h3{
        font-size:px(16);
        margin: 0;
        width: 100%;
    }
    input::-webkit-input-placeholder {
           font-size: px(10);
           padding-left: 0;
           width: px(170);
           text-align: left;
           transform: scale(0.95)
        }
    .freeExp{
        font-size: px(10);
    }
    .someMsg{
        width: 100%;
        background: #26292B;
        padding: 0 px(15);
    }
    .footerMsg img{
        margin-top: px(20);
        width: px(142);
        margin-left: px(102);
        margin-bottom: px(22);
    }
    .consult{
        height: px(49);
        .consult_list {
            display: flex;
            width: 62%;
            .msg {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;
                // flex: 1;
                // display: flex;
                // flex-direction: column;
                // justify-content: center;
                // align-items: center;
                // font-size: rem(22) !important;
                // font-weight: normal;
                // font-stretch: normal;
                // letter-spacing: 1.3px;
                // color: #666666;
            }
        }
    }
    .index_pic{
        background: url(../../image/mobileImage/index.png) 100% 100%;
        background-size: px(23) px(22);
        width: px(23);
        height: px(22);
        margin: 0 auto;
    }
    .msg_img{
        background: url(../../image/mobileImage/zaixian.png) 100% 100%;
        background-size: px(23) px(22);
        width: px(23);
        height: px(22);
        margin: 0 auto;
    }
    .us{
        font-size:px(14);
        font-family:PingFang SC;
        font-weight:bold;
        color:rgba(255,255,255,1);
        margin-bottom: px(15);
        line-height: px(12);
    }
    .usMsg{
        font-size:px(12);
        font-family:PingFang;
        font-weight:500;
        color:rgba(179,179,179,1);
        margin-bottom: px(11);
        line-height: px(12);
    }
    .flexMsg{
        display: flex;
        height: px(32);
        .usMsg{
            height: px(12);
        }
    }
    .aboutMsg{
        margin-left: px(77);
        margin-right: px(83);
    }
    .usMsgLast{
        margin-bottom: px(20);
    }
    .flexMsgLast{
        margin-bottom: px(20);
    }
    .line{
        border-top: 1px solid rgba(52,56,59,1);
        padding-bottom:px(31);
        padding-top: px(5);
    }
    .footerMsgLast{
        margin-top: px(10);
        font-size:px(12);
        font-family:PingFang SC;
        font-weight:500;
        color:rgba(230,230,230,1);
        text-align: center;
        line-height: px(12);
    }
    .tel_pic{
        background: url(../../image/mobileImage/tel.png) 100% 100%;
        background-size: px(23) px(22);
        width: px(23);
        height: px(22);
        margin: 0 auto;
    }
    .telMsg{
        color: #333
    }
    .freeBg{
        width:px(140);
        height: px(49);
        background:rgba(254,76,86,1);
        a {
            display: flex;
            width: 100%;
            height: 100%;
            text-decoration: none;
            justify-content: center;
            align-items: center;
        }
    }
    .freeToUse{
        font-size:px(16);
        font-family:PingFang;
        font-weight:500;
        color:rgba(255,255,255,1);
        margin-top:-5px !important
    }
</style>
