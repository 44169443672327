<template>
<div class="customer_service">
  <a :href="'tel: ' + tel_pic"></a>
</div>
</template>

<script>
    export default {
        props: ['paramers'],
        data() {
            return {
                tel_pic: '400-089-9796'
            }
        },
        mounted() {
            //  let data = JSON.parse(this.paramers);

            //  if (data['tel_pic']) {
            //      this.tel_pic = data['tel_pic'];
            //  }
        }
    }
</script>
<style scoped lang='scss'>
@function px( $px ){
  @return $px/$designWidth*10 + rem;
}
$designWidth : 375;
.customer_service {
    position: fixed;
    top: 50%;
    right: px(10);
    width: px(54);
    height: px(54);
    z-index: 999;
    transform: translateY(-50%);
    background: url('https://img.qianhuituan.cn/web/images/customer_service.png') no-repeat;
    background-size: 100% 100%;
    a {
        display: inline-block;
        width: 100%;
        height: 100%;
    }
}
</style>
